import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const InfoList = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お知らせ一覧｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-infoPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-2xl pt-10 pb-20">Information</h1>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

          <section id="infoList" className="mb-20">
            <h2 className="text-2xl border-b border-key-sand my-5 py-3 ">
              お知らせ一覧
            </h2>

            <ol className="list-none flex flex-wrap items-stretch justify-center sm:justify-start">

              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug;
                const image = getImage(post.frontmatter.thumbnail)

                return (
                <Link to={`${post.fields.slug}`} itemProp="url">
                  <li key={post.fields.slug} className="p-3 w-full max-w-[330px] h-[420px]">
                    <article
                    className="post-list-item border border-gray-500 h-full bg-white"
                    itemScope
                    itemType="http://schema.org/Article"
                    >
                      <div className="w-full max-w-[330px] h-[170px]">
                      {
                        post.frontmatter.thumbnail ?
                        <GatsbyImage image={image} alt={post.frontmatter.title} />
                        :
                        <StaticImage src="../images/noImage.png" className="object-cover"/>
                      }
                      </div>
                      <header className="px-3 pt-3">
                        <p className="text-sm">
                          <div className="inline-block">
                            <svg class="inline h-5 w-5 mb-1"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
                            <span className="ml-1">{post.frontmatter.date}</span>
                          </div>
                          <div className="inline-block ml-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-folder inline-block" viewBox="0 0 16 16">
                              <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                            </svg>
                            <span className="ml-1 ">{post.frontmatter.category}</span>
                          </div>
                        </p>
                        <h2 className="font-medium my-2 min-h-[50px] ">
                          <span itemProp="headline">{title}</span>
                        </h2>
                      </header>
                      <section className="px-3 pb-3">
                          <p
                          className="line-clamp-4" 
                          dangerouslySetInnerHTML={{
                              __html: post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                          />
                      </section>
                    </article>
                  </li>
                </Link>
                )
              })}
            </ol>

          </section>


          </div>
        </div>
      </div>

    </Layout>
  )
}

export default InfoList

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(truncate: true)
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "YYYY/MM/DD")
          description
          tags
          category
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 330
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
              original{
                src
              }
            }
          }
        }
      }
    }
  }
`